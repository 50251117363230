// SalesReportPage.jsx
import React, { useState, useEffect } from 'react';
import HeadingCp from '../components/HeadingCp';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyFormat from '../components/CurrencyFormat';
import { useNavigate } from 'react-router-dom';

const SalesReportPage = () => {
  const [activeTab, setActiveTab] = useState('proposals');
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [projects, setProjects] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  // Fetch data on component mount
  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/report_crud.php`, {
        params: { getReport: true },
      });
      setMetrics(response.data.metrics || []);
      setProposals(response.data.proposals_stats.proposal_sent || []);
      setProjects(response.data.projects || []);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error(
          "Oops!! you don't have access to this area. Speak to the CFO.",
          {
            position: 'top-center',
          }
        );
        navigate('/');
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle print action
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-4 lg:px-6 py-10">
      {/* Header and Metrics Bar */}
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <HeadingCp label="Sales Report" />
        </div>
        {/* Print Button */}
        <div className="mt-4 sm:mt-0 sm:ml-4">
          <button
            onClick={handlePrint}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 print:hidden"
          >
            Print
          </button>
        </div>
      </div>

      {/* Metrics Bar */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 mb-10">
        <div className="p-4 bg-white shadow rounded-lg">
          <p className="text-sm font-medium text-gray-500">
            Proposals Sent - YTD
          </p>
          <p className="mt-1 text-2xl font-semibold text-gray-900">
            {metrics.total_proposals_sent} @{' '}
            <CurrencyFormat value={metrics.total_proposals_sent_sum} />
          </p>
        </div>

        <div className="p-4 bg-white shadow rounded-lg">
          <p className="text-sm font-medium text-gray-500">
            Projects Awarded - YTD
          </p>
          <p className="mt-1 text-2xl font-semibold text-gray-900">
            {metrics.total_awared_projects} @{' '}
            <CurrencyFormat value={metrics.total_awared_projects_sum} />
          </p>
        </div>

        <div className="p-4 bg-white shadow rounded-lg">
          <p className="text-sm font-medium text-gray-500">
            Total | WIP Total - YTD
          </p>
          <p className="mt-1 text-2xl font-semibold text-gray-900">
            <CurrencyFormat value={metrics.workinprogress?.totalPriced} /> |{' '}
            <span className="text-green-600">
              <CurrencyFormat value={metrics.workinprogress?.totalWip} />
            </span>
          </p>
        </div>

        <div className="p-4 bg-white shadow rounded-lg">
          <p className="text-sm font-medium text-gray-500">
            Outstanding Balance
          </p>
          <p className="mt-1 text-2xl font-semibold text-gray-900">
            <span className="text-red-600">
              <CurrencyFormat value={metrics.outstanding_balance} />
            </span>
          </p>
        </div>
      </div>

      {/* Tabs */}
      <div className="mb-6 border-b border-gray-200 print:hidden">
        <nav className="-mb-px flex space-x-4">
          <button
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'proposals'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('proposals')}
          >
            Proposals
          </button>
          <button
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'projects'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab('projects')}
          >
            Projects
          </button>
        </nav>
      </div>

      {/* Content */}
      {activeTab === 'projects' && (
        <div className="flow-root mb-10">
          <h2 className="text-lg font-semibold text-gray-900 mb-4 print:mt-10">
            Projects
          </h2>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Year-Month
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Projects Awarded
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Projects Sum
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {projects.map((project, idx) => (
                    <tr key={idx}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {project.month}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {project.total_projects}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <CurrencyFormat value={project.total_price} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'proposals' && (
        <div className="flow-root mb-10">
          <h2 className="text-lg font-semibold text-gray-900 mb-4 print:mt-10">
            Proposals
          </h2>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Year-Month
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Proposals Sent
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Proposals Sum
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {proposals.map((proposal, idx) => (
                    <tr key={idx}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {proposal.month}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {proposal.total_proposals}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <CurrencyFormat value={proposal.total_price} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesReportPage;
