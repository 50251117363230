// src/utils/dateUtils.js
// import { DateTime } from 'luxon';

// export const dateToUnixTimestamp = (dateString) => {
//   // Parse the date string in the America/New_York time zone
//   const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

//   // Get the Unix timestamp in seconds
//   const timestampSeconds = Math.floor(date.toSeconds());

//   return timestampSeconds;
// };

export const dateToUnixTimestamp = (dateString) => {
    // Create a Date object from the date string
    var date = new Date(dateString);

    // Get the Unix timestamp in milliseconds
    var timestampMillis = date.getTime();

    // Convert to seconds
    var timestampSeconds = Math.floor(timestampMillis / 1000);

    return timestampSeconds;
  }
// utils/dateToUnixTimestamp.js
export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};