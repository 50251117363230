// ReimbursementListCp.jsx

import React, { useState, useMemo } from 'react';
import Modal from '../components/Modal';
import FileLocationCp from '../components/FileLocationCp';
import { ArrowUpCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from './DateFormat';
import FileUploadModal from './FileUploadModal';
import CurrencyFormat from './CurrencyFormat';
import SelectEmployeeCp from '../components/SelectEmployeeCp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import { dateToUnixTimestamp, addDays } from '../utils/dateToUnixTimestamp';


const ReimbursementListCp = ({
                                reimbursements = [],
                                searchTerm = '',
                                onEditReimbursement,
                                onAddReimbursement,
                                access = 'User',
                                onDeleteReimbursement,
                                onFileUpload,
                                onApprovedReimbursement,
                                week
                              }) => {

  const [editMode, setEditMode] = useState(null);
  const [editedReimbursement, setEditedReimbursement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedReimbursementId, setSelectedReimbursementId] = useState(null);
  const navigate = useNavigate()
  const [addEmployee, setAddEmployee] = useState({employee_id:''});
  const [selectEmployee, setSelectedEmployee] = useState(false);
  const [newReimbursement, setNewReimbursement] = useState({
    employee_id: '',
    payroll_date: '',
    amount: '',
    file: '',
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  // Handle editing an existing reimbursement
  const handleEditClick = (reimbursement) => {
    setEditMode(reimbursement.id);
    setEditedReimbursement({ ...reimbursement });
  };

  // Handle input changes when editing
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReimbursement((prev) => ({ ...prev, [name]: value }));
  };

  // Save the edited reimbursement
  const handleSaveClick = () => {
    onEditReimbursement(editedReimbursement);
    setEditMode(null);
  };

  // Handle input changes when adding a new reimbursement
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewReimbursement((prev) => ({ ...prev, [name]: value }));
  };

  // Add a new reimbursement
  const handleAddClick = () => {
    onAddReimbursement(newReimbursement, selectEmployee);
    setNewReimbursement({
      employee_id: '',
      payroll_date: '',
      amount: '',
      file: '',
    });
    setIsModalOpen(false);
  };

  // Handle file selection for upload
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  // Handle file upload
  const handleUploadFile = (e) => {
    e.preventDefault();
    onFileUpload(file, selectedReimbursementId);
    setFile(null);
    setIsModalFileOpen(false);
  };

  // Delete a reimbursement
  const handleDelete = (id) => {
    onDeleteReimbursement(id);
  };

  // Filter reimbursements based on search term
  const filteredReimbursements = useMemo(() => {
    if (!Array.isArray(reimbursements)) return [];
    const searchTermLower = (searchTerm || '').toLowerCase();
    return reimbursements.filter((reimbursement) => {
      const employeeName = (reimbursement.employee_name || '').toLowerCase();
      return employeeName.includes(searchTermLower);
    });
  }, [reimbursements, searchTerm]);

  const handleRem = (id) => {
      onApprovedReimbursement(id);
  }


  return (
    <>
      {/* Header with Add Reimbursement button */}
      <div className={`sm:flex sm:items-left ${filteredReimbursements.length > 0 ? '' : 'float-left'}`}>
        <div className={`sm:flex-auto ${filteredReimbursements.length > 0 ? '' : 'hidden'}`}></div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm
                      hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                      focus-visible:outline-indigo-600"
            onClick={() => setIsModalOpen(true)}
          >
            Add Reimbursement
          </button>
        </div>
      </div>
      <br />

      {filteredReimbursements.length > 0 ? (
        <>
          {/* Reimbursement Table */}
          <div className="px-4 sm:px-6 lg:px-8 mt-8 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="mx-auto divide-y divide-gray-300 border">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                      >
                        Employee Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                      >
                        Payroll Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                      >
                        File
                      </th>
                      {access === 'Admin' && (
                        <th
                          scope="col"
                          className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredReimbursements.map((reimbursement, index) => (
                      <tr
                        key={reimbursement.id}
                        className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}
                      >
                        {editMode === reimbursement.id ? (
                          <>
                            {/* Edit Mode Row */}
                            <td className=" py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                              <TrashIcon
                                className="h-5 text-red-600 hover:cursor-pointer"
                                onClick={() => handleDelete(reimbursement.id)}
                              />
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="employee_name"
                                value={editedReimbursement.employee_name || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              <input
                                type="text"
                                name="description"
                                value={editedReimbursement.description || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              <input
                                type="date"
                                name="payroll_date"
                                value={editedReimbursement.payroll_date || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md"
                              />
                            </td>
                            <td className=" py-4 text-sm text-gray-500 max-w-10">
                              <input
                                type="number"
                                name="amount"
                                value={editedReimbursement.amount || ''}
                                onChange={handleInputChange}
                                className="border-gray-300 rounded-md max-w-full"
                              />
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              {/* File upload placeholder */}
                            </td>
                            {access === 'Admin' && (
                              <td className=" py-4 pl-3 pr-4 text-right text-sm font-medium">
                                <button
                                  onClick={handleSaveClick}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Save
                                </button>
                              </td>
                            )}
                      </>
                        ) : (
                          <>
                            {/* Display Mode Row */}
                            <td className=" py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                              {/* {reimbursement.id} */}
                              {reimbursement.status === 0 ? (
                                <button
                                  onClick={() => handleRem(reimbursement.id)}
                                  className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium 
                                            rounded-md shadow-sm text-white bg-green-500 hover:bg-blue-600 focus:outline-none 
                                            focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                  Approve
                                </button>                                
                              ) : ('Approved')}
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              {reimbursement.employee_name || 'Name Added..'}
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              {reimbursement.description || ''}
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              <DateFormat date={reimbursement.payroll_date} />
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              <CurrencyFormat value={reimbursement.amount}/>
                            </td>
                            <td className=" px-3 py-4 text-sm text-gray-500">
                              <FileLocationCp
                                FileName={reimbursement.file}
                                where={'rem_file'}
                                notFoundButton={
                                  <ArrowUpCircleIcon
                                    className={`h-5 text-green-600 hover:cursor-pointer hover:text-blue-600`}
                                    onClick={() => {
                                      setIsModalFileOpen(true);
                                      setSelectedReimbursementId(reimbursement.id);
                                    }}
                                  />
                                }
                              />
                            </td>
                            {access === 'Admin' && (
                              <td className=" py-4 pl-3 pr-4 text-right text-sm font-medium">
                                <button
                                  onClick={() => handleEditClick(reimbursement)}
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                </button>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
        <br/>
        <br/>
        <p className='ml-20'>No reimbursements found.</p>
        </>
      )}

      {/* Add Reimbursement Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Add New Reimbursement</h2>
        <form>
          
          <div className="mt-4">
            <SelectEmployeeCp 
              selected={selectEmployee} 
              setSelected={setSelectedEmployee} 
              url={'/employee_crud.php?getEmployeeList=true'}
              size={'w-full'}/>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <input
              type="text"
              name="description"
              value={newReimbursement.description || ''}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Payroll Date</label>
            <input
              type="date"
              name="payroll_date"
              value={newReimbursement.payroll_date}
              required={true}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Amount</label>
            <input
              type="number"
              name="amount"
              value={newReimbursement.amount}
              onChange={handleNewInputChange}
              className="mt-1 block w-1/4 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          {/* You can add a file input here if needed */}
          <div className="mt-6">
            <button
              disabled={!newReimbursement.amount || !newReimbursement.description || !newReimbursement.payroll_date || !selectEmployee}
              onClick={handleAddClick}
              type="button"
              className={`inline-flex justify-center rounded-md border border-transparent
                          ${
                            !newReimbursement.amount || !newReimbursement.description || !newReimbursement.payroll_date || !selectEmployee
                              ? 'bg-gray-400'
                              : 'bg-indigo-600 hover:bg-indigo-700'
                          }
                          py-2 px-4 text-sm font-medium text-white shadow-sm 
                          focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Add
            </button>
          </div>
        </form>
      </Modal>

      {/* File Upload Modal */}
      <FileUploadModal
        isOpen={isModalFileOpen}
        onClose={() => setIsModalFileOpen(false)}
        onSubmit={handleUploadFile}
        onFileChange={handleFileChange}
        accept="application/pdf, image/png, image/jpeg" // Accept multiple file types
        title="Upload Reimbursement File"
        label="Select a file (PDF, PNG, JPEG) (Max size 2MB)"
      />
    </>
  );
};

export default ReimbursementListCp;
