import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../components/SubmitButton';
import CurrencyFormat from '../components/CurrencyFormat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopTabs from '../components/TopTabs';

  const CustomerPage = () => {

  const navigate = useNavigate();
  const { id, key } = useParams();
  const [customer, setCustomer] = useState({
                                              id: '',
                                              customer_name: '',
                                              created_at: '',
                                              hold: '',
                                              hold_amount: '',
                                              hold_comment: '',
                                              active: 1,
                                              notes: '',
                                           });

  const customerList = { name: 'Customer List', href: '/customers', current: false };
  const customerTab = { name: 'Customer', href: '#', current: true };
  const billto = { name: 'Bill to', href: `/customer_billto/edit/${id}`, current: false };
  
  const tabs = id === 'new' 
  ? [customerList, customerTab]
  : [customerList, customerTab, billto]; //edit                                
                                       

  function capitalizeFirstLetter(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect( () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchCustomer = async () =>{
      if(key === 'edit')
      {
        try {
              const res = await fetch(`${apiUrl}/customer_crud.php?getDetail=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }
              });
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        if(data.error){
          navigate('/customer')
          toast.error(data.error, {
            position:'top-center'
          });
        }

        setCustomer(data);

        } catch(error) {
          console.log('Error fetching data', error);
        } finally {
          // setLoading(false);
        }
      } 
    }

    fetchCustomer();
  }, [id, key, navigate])

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setCustomer(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  const handleSubmit = async (event) => {
      event.preventDefault();  // Prevent the default form submit behavior
      const apiUrl = process.env.REACT_APP_API_URL;

      const bodyData = {
          status: 'submit',
          key: key,
          data: customer
      };
      try {
          const response = await axios.post(`${apiUrl}/customer_crud.php`, bodyData, {
              headers: { 'Content-Type': 'application/json' }
          });
          const data = response.data;  
          if(data.error)
          {
            toast.info(data.error, {
              position:'top-center'
            })
            return
          } else {
            if(id === 'new'){
              navigate(`/customer_billto_edit/${data.id}/billto/new`); //customer_billto_edit/91/88/new
            }      
          }
      } catch (error) {
          if (error.response) {
              console.error('Error submitting customer', error.response.data);
          } else if (error.request) {
              console.error('Error submitting proposal', error.request);
          } else {
              console.error('Error', error.message);
          }
      }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5">
      <form onSubmit={handleSubmit} className='w-[800px] px-2 bg-white border rounded-lg py-8 px-8'>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{capitalizeFirstLetter(key)} Customer</h2>
            <p className="mt-1 text-sm leading-6 text-red-600">* Denotes Required Fields</p>
          </div>
          <TopTabs tabs={tabs} />

          <div className="border-b border-gray-900/10 pb-12 ">
            <h6 className="text-base font-semibold leading-7 text-gray-600">{id !== 'new' ? 'CustomerID:' : '' } {customer.id}</h6>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="active"
                    name="active"
                    value={customer.active ? 'true' : 'false'}
                    checked={customer.active || ''}
                    onChange={handleChange}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label htmlFor="active" className="font-medium text-gray-900">
                    Active
                  </label>
                </div>
            </div>

              <div className="col-span-full">
                <label htmlFor="customer_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Name <span className='text-red-500'>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="customer_name"
                    id="customer_name"
                    value={customer.customer_name || ''} 
                    onChange={handleChange}
                    autoComplete="customer_name"
                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className='sm:col-span-6'>
                <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Notes
                </label>
                <div className="mt-2">
                  <textarea
                    rows={4}
                    name="notes"
                    id="notes"
                    value={customer.notes || ''}
                    onChange={handleChange}
                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>              

              <div className="sm:col-span-3">
                <label htmlFor="hold_comment" className="block text-sm font-medium leading-6 text-gray-900">
                  Credit hold comment
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="hold_comment"
                    id="hold_comment"
                    value={customer.hold_comment || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="hold"
                      name="hold"
                      value={customer.hold ? 'true' : 'false'}
                      checked={customer.hold || ''}
                      onChange={handleChange}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="hold" className="font-bold text-red-600">
                      Credit Hold
                    </label>
                  </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                  Amount owed (only digits) <span className='text-red-500'>* </span> 
                   :: <span className='text-gray-400'>${<CurrencyFormat value={customer.hold_amount || ''}/> || ''}</span>
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="hold_amount"
                    id="hold_amount"
                    value={customer.hold_amount || ''}
                    onChange={handleChange}
                    autoComplete="hold_amount"
                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <NavLink to="/customers">
           <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
          </NavLink>
          <SubmitButton disabled={!customer.customer_name} children={'Submit'} /> 
        </div>
      </form>
      
    </div>
  )
}

export default CustomerPage