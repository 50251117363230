import React, { useState, useEffect } from 'react';
import Badge from '../components/Badge';
import { NavLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CurrencyFormat from '../components/CurrencyFormat';
import { ArrowPathIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import SubMenuJob from '../components/SubMenuJob';
import NotesOverlayJob from '../components/NotesOverlayJob';
// import StatsJob from '../components/StatsJob';
import TabsJob from '../components/TabsJob';
import DateFormat from '../components/DateFormat';
import SearchBarCP from '../components/SearchBarCP';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../custom.css';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
// import LegendCP from '../components/LegendCP';
import ModalWindow from '../components/ModalWindow'
import ModalWipNotesSection from '../components/ModalWipNotesSection';
import WipComments from '../components/ModalWipNotesSection';
import { useUserData } from '../context/UserData';
import Modal from '../components/Modal';
import ToolTipCp from '../components/ToolTipCp';
import { ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import SignedProposalCP from '../components/SignedProposalCP';
import LoadingSpinner from '../components/LoadingSpinner';
import HeadingCp from '../components/HeadingCp';
import classNames from 'classnames';


const WipListPage = () => {
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);//file
  const [file, setFile] = useState(null);
  const [selectJobId, setSelectJobId] = useState(null);
  const [count, setCount] = useState(0);

  const handleUploadFile = (e) => {
    e.preventDefault();
    handleFormSubmit(file, selectJobId); // Changed here
    setFile(null);
    setIsModalFileOpen(false);
  };

  const {key} = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [jobNumber, setJobNumber] = useState(null);
  const [newObjct, setNewObjct] = useState({
                                            id: '',
                                            amount: '',
                                            notes: ''
                                          });
  const userData = useUserData();
  const [jobs, setJobs] = useState([]);
  const [creditHold, setCreditHold] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [bodyDataNotes, setBodyDataNotes] = useState({
                                                       status: 'submit_notes',
                                                       data: {notes: ''}
                                                      });
  const [loading, setLoading] = useState(true);
  const [showVoid, setShowVoid] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const navigate = useNavigate();
  const preWageClass = "hidden px-3 py-4 text-sm text-gray-500 sm:table-cell bg-green-300";
  // const [openNotes, setOpenNotes] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/wip_crud.php`
  const [openNotesJobId, setOpenNotesJobId] = useState(null); // Stores the ID of the job for which notes are shown
  

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF, PNG, and JPEG are allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
    //console.log(selectedFile, 'selected file');
    
  };

  const handleFormSubmit = async (file, selectJobId) => {
    if (!file) {
      toast.error("Please upload a valid file.", { position: 'top-center' });
      return;
    }
    //console.log(selectJobId, file, 'handle form submit');
    
   const formData = new FormData();
    formData.append('status', 'submit_signed_proposal');
    formData.append('id', selectJobId); // Include certificate ID
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/signed_proposal.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      //console.log(response.data);
      
        setFilteredJobs(jobs => jobs.map(job => {
        if (job.id === response.data?.job.id) {
          return { ...job, file: response.data.file };
        }
            return job;
        }));

      if (response.data.success) {
        toast.info('Saved!', { position: 'top-center' });
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", { position: "top-center" });
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
      }
    }
  };
  
  const handleNoteChangeParent = (id, newNote) => {
    
    setFilteredJobs(prev => prev.map(job => {
      if (parseInt(job.id) === id) {
        return {
          ...job,
          wip_notes: Array.isArray(job.wip_notes) ? [...job.wip_notes, newNote] : [newNote]
        };
      } else {
        return job;
      }
    }));
  };

  const taxBadge = () => {
    return { color: "purple", num: "800" };
  }

  const getBadgeProps = (type) => {
    switch (type) {
        case 'New System - FA':
            return { color: "pink", num: "900" };//
        case 'Upgrade':
            return { color: "yellow", num: "900" };//
        case 'Floor Work':
            return { color: "indigo", num: "900" };//
        case 'Repair':
            return { color: "green", num: "900" };//
        case 'Filing':
            return { color: "purple", num: "900" };
        default:
            return { color: "gray", num: "900" }; //// Default case
    }
  };

  const toggleShowVoid = () => {
        setShowVoid(!showVoid);
  };

  const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);

      if (value === '') {
          setFilteredJobs(jobs); // Reset to all jobs if search is cleared
      } else {
          const filtered = jobs.filter(job =>
              (job.customer_name && job.customer_name.toLowerCase().includes(value.toLowerCase())) ||
              (job.job_number && job.job_number.toLowerCase().includes(value.toLowerCase())) ||
              (job.job_site && job.job_site.toLowerCase().includes(value.toLowerCase()))
          );
          setFilteredJobs(filtered);
      }
  };

  const handleSearchClick = async () => {
    if (!searchTerm) {
      toast.info('Search bar is empty', { position: 'top-center' });
      return;
    }

    setLoading(true);

    try {
      const bodyData = {
        status: 'search',
        data: { search: searchTerm }
      };

      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data && Array.isArray(response.data)) {
        setFilteredJobs(response.data.filter(job => showVoid ? job.void === 1 : job.void === 0));

        // Save the search state in sessionStorage
        sessionStorage.setItem('wipSearchTerm', searchTerm);
        sessionStorage.setItem('wipFilteredJobs', JSON.stringify(response.data));
        sessionStorage.setItem('wipShowVoid', showVoid);
      } else {
        setFilteredJobs([]);
        toast.error('No results found for ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching active projects', error);
      toast.error('An error occurred during the search.', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    // Clear sessionStorage and reset the state
    sessionStorage.removeItem('wipSearchTerm');
    sessionStorage.removeItem('wipFilteredJobs');
    sessionStorage.removeItem('wipShowVoid');
    
    setSearchTerm('');
    setFilteredJobs([]);
    setShowVoid(false);
     window.location.reload();
  }; 

  const handleChange = (event, id, user) => {
      bodyDataNotes.wip_id = id;
      const { name, value } = event.target;
      setFilteredJobs(prev => prev.map(job => {
          if (parseInt(job.id) === id) {
              // Create an additional variable or perform additional logic here
              const updatedJob = { ...job, [name]: value };
              bodyDataNotes.data = updatedJob;
              // // You can also update the updatedJob object if needed
              updatedJob.employee_name = user.first_name+" "+user.last_name;
              return updatedJob;
          } else {
              return job;
          }
      }));
  };

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${apiUrl}/wip_crud.php?getWipList=${key}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      if (response.data.location === "field") {
        navigate('/t'); // Redirect to login if the cookie is invalid or expired
        // toast.error("Tech Section", { position: "top-center" });
      } else if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
        const jobList = Array.isArray(response.data[0]) ? response.data[0] : [];
        if (jobList.length === 0) {
          toast.error("No projects found!", { position: "top-center" });
        }
        setJobs(jobList);
        setFilteredJobs(jobList);
        setCount(response.data[1]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
      }
    } finally {
      setLoading(false);
    }
  };

  // New useEffect to load state from sessionStorage on mount
  useEffect(() => {
    const savedSearchTerm = sessionStorage.getItem('wipSearchTerm');
    const savedFilteredJobs = sessionStorage.getItem('wipFilteredJobs');
    const savedShowVoid = sessionStorage.getItem('wipShowVoid');

    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
    }

    if (savedFilteredJobs) {
      setFilteredJobs(JSON.parse(savedFilteredJobs));
    } else {
      fetchJobs(); // Fetch jobs only if there's no saved state
    }

    if (savedShowVoid) {
      setShowVoid(JSON.parse(savedShowVoid));
    }

    setLoading(false);
  }, [key]);

  const editJob = (id) =>{
      // e.preventDefault()
      navigate(`/wip/edit/${id.idItem}`)
  }

  const voidJobSubmit = async(id, currentVoidState) => {
    setLoading(true);
    
    const newVoidStatus = currentVoidState === 1 ? 0 : 1;
    const bodyData = {
      status: 'void',
      data: { voidID: id, void:newVoidStatus }
    };

    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data === 'Success') {
          setFilteredJobs(jobs => jobs.map(job => {
        if (job.id === id) {
          return { ...job, void: newVoidStatus };
        }
            return job;
        }));

        toast.success('Saved!', {
          position: 'top-center'
        })
      } else {
        toast.error('error updating', {
          position: 'top-center'
        });
      }
    } catch (error) {
      ////console.error('Error searching active projects', error);
    } finally {
      setLoading(false);
    }
   }

  const completeSubmit = async(id, completeStatus) => {
    setLoading(true);
    
    const newCompleteStatus = completeStatus === 1 ? 0 : 1;
    const bodyData = {
      status: 'complete',
      data: { completeID: id, complete:newCompleteStatus }
    };

    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data === 'Success') {
          setFilteredJobs(jobs => jobs.map(job => {
        if (job.id === id) {
          return { ...job, complete: newCompleteStatus };
        }
            return job;
        }));

        toast.success('Saved!', {
          position: 'top-center'
        })
      } else {
        toast.error('error updating', {
          position: 'top-center'
        });
      }
    } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });
        
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
   }

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewObjct((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreditHold = async () => {
    // Implement the form submission logic here
    const bodyData = {
      status: 'submit_credit_hold',
      data: newObjct,
      jobNumber: jobNumber
    };
    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
        // ////console.log(response.data);
        
      if (response.data[0] === 'success') {
        toast.success('Credit Hold Added!', {
          position: 'top-center'
        });
        setFilteredJobs(jobs => jobs.map(job => {
        if (job.job_number === response.data.credit_hold.job_number) {
          return { ...job, credit_hold: response.data?.credit_hold };
        }
            return job;
        }));
        setJobNumber(null)
        setNewObjct({
                      id: '',
                      amount: 0,
                      notes: ''
                    })
          setIsModalOpen(false);
        // Handle additional logic here if needed
      } else {
        toast.error('Error adding credit hold.', {
          position: 'top-center'
        });
      }
    } catch (error) {
      //console.error('Error adding credit hold', error);
      toast.error('Error adding credit hold.', {
        position: 'top-center'
      });
    }
  };

  const handleCreditHoldDelete = async(id, jobID) => {
    const bodyData = {
      id: id,
      status: 'delete_credit_hold'
    };
      try {
        const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        
        if (response.data[0] === 'success') {
          toast.success("Hold Removed!", {
            position: "top-center"
          });
        
          setFilteredJobs(jobs => jobs.map(job => {
          if (job.id === jobID) {
            return { ...job, credit_hold: false};
          }
              return job;
          }));

        } else {
          toast.error(response.data.error || "Failed deleting.", {
            position: "top-center"
          });
        }
      } catch (error) {
          toast.error("Oops minor issue with the data.", {
          position: "top-center"
          });
          
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
            // setAccess(true);
          toast.error("Oops!! no access to edit.", {
          position: "top-center"
          });
          }
      }
  }

  const getLinksForJob = (job) => {
    let links = [
      { name: 'Edit Job', click: editJob },
      { name: job.void === 1 ? 'Remove void' : 'Void Job', click: (e, tvoid, index) => voidJobSubmit(job.id, job.void, index) },
      { name: job.complete === 1 ? 'Remove complete' : 'Complete Job', click: (e, jcomplete, index) => completeSubmit(job.id, job.complete, index) },
      { name: job.credit_hold.job_number === job.job_number ? 'Remove Hold' : 'Add Credit Hold',
              click: () => {
                             if(job.credit_hold.job_number === job.job_number)
                             {
                              handleCreditHoldDelete(job.credit_hold.id, job.id)
                             }
                             else
                             {
                              setJobNumber(job.job_number);
                              setIsModalOpen(true); 
                            }
                           }
      },
      { name: job.file ? 'Update Signed Proposal' : 'Upload Signed Proposal', click: () => {setIsModalFileOpen(true); setSelectJobId(job.id)} },
      // { name: 'Upload Delivery Receipt', click: '' }
    ];

    return links;
  };


  return (
    <>
      <div className="mx-auto max-w-[1200px] px-4 sm:px-6 lg:px-8">

        <div className="sm:flex sm:items-center sm:justify-between">

        <div className="sm:flex-auto mt-8 mb-20">
          <HeadingCp label={'WIP (work in progress)'} />
        </div>
         <LegendCP color="fill-yellow-300" label="in-progress" /> 
         <LegendCP color="fill-green-400" label="Complete" /> 
         <LegendCP color="fill-red-600" label="Company On Hold" /> 
         <LegendCP color="fill-red-300" label="Credit Hold" /> 
        </div>

        <div className="-mx-4 mt-8">
          {/* {count && (<p className='p-3'>WIP count: {count}</p>)} */}
        <div className="mt-5 sm:mt-0 sm flex space-x-3 items-center mb-10">

        <NavLink to="/proposal_list">
          <button type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                        shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 
                        focus:ring-offset-2 focus:ring-indigo-500">
            Proposal List
          </button>
        </NavLink>
        
        <button
          onClick={toggleShowVoid}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
          {showVoid ? 'Show Non-Void' : 'Show Void'}
        </button>

        <NavLink to={'/credit_hold'}>
        <span
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                     shadow-sm text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                     focus:ring-red-800 hover:cursor-pointer">
          Credit Hold List
        </span>
        </NavLink>

        {/* <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} /> */}

        <div className="relative rounded-md shadow-sm flex-grow">
            <input
            type="text"
            name="search"
            value={searchTerm}
            required={true}
            onChange={handleSearchChange}
            className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter a search term"
          />
          <button
            onClick={handleSearchClick}
            className="absolute right-20 top-0 bottom-0 bg-green-600 hover:bg-green-700 text-white px-3 py-2 mr-4">
            Search
          </button>

          <button
            onClick={handleReset}
            className="absolute right-0 top-0 bottom-0 bg-orange-600 hover:bg-orange-700 text-white px-3 py-2 rounded-r-md">
            <span className='flex items-center gap-1'><ArrowPathIcon className='h-5'/> Reset</span>
          </button>
        </div>
        </div>
        <TabsJob />

        {loading ? (
          <LoadingSpinner children={'Loading....'}/>
        ) : (
   <table className="min-w-full divide-y divide-x divide-gray-300 border rounded-lg">
      <thead>
        <tr className='divide-x divide-gray-400 bg-gray-200'> 
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
            ID/Edit
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            Proposal # {/* job_number */}
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            Job Site | Customer {/* job_site */}{/* customer_id */}
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            Job Description {/* job_description */}
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            F/P {/* f_p_type */}
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            Price | Tax {/* price */} {/* Tax */} {/* prev_wage */}
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            I% {/* invoiced */}
          </th>
          <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            P% {/* paid */}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Date {/* f_u_date */}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            PM {/* pm */}
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Notes
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">         
        {filteredJobs?.filter(job => showVoid ? job.void === 1 : job.void === 0)
          .map((job, index) => (
            <tr
              key={job.id}
              className={classNames(
                {
                  'line-through text-red-600': job.void === 1,
                  'bg-red-200': job.credit_hold,
                  'bg-green-200': job.complete === 1 && !job.credit_hold,
                },
                {
                  'bg-white': !job.credit_hold && !job.complete && index % 2 === 0,
                  'bg-gray-100': !job.credit_hold && !job.complete && index % 2 !== 0,
                },
                'divide-x'
              )}
            >
              {/* ID/Edit Cell */}
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-2">
                <SubMenu label={job.id} idItem={job.id} links={getLinksForJob(job)} />
                <dl className="font-normal lg:hidden">
                  <dd className="mt-1 truncate text-gray-700">{job.company}</dd>
                  <dd className="mt-1 truncate text-gray-500 sm:hidden">{job.contractor}</dd>
                  <dd className="mt-1 truncate text-gray-500 sm:hidden">{job.jobnum}</dd>
                </dl>
              </td>

              {/* Proposal # / Job Number Cell */}
              <td className="hidden px-3 py-2 text-sm text-gray-500 sm:table-cell">
                <p className='text-[12px]'>
                  {job.credit_hold && job.credit_hold.amount > 0 && (
                    <span className='text-red-800'>Owed: <CurrencyFormat value={job.credit_hold.amount} /></span>
                  )}
                  <br/>
                  <NavLink to={`/wip_detail/${job.id}`}>
                    <button className='text-blue-500'>{job.job_number}</button>
                  </NavLink>
                </p>
                <p className="text-[10px] text-gray-500">
                  <Badge feed={job.type} {...getBadgeProps(job.type)} />
                </p>
              </td>

              {/* Job Site | Customer Cell */}
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                <p className={`text-sm font-medium text-gray-900 font-bold`}>
                  {job.job_site}
                </p>
                <span className="flex items-center">
                  <p className={`text-sm ${job.customer_hold ? 'text-red-700' : 'text-gray-500'}`}>
                    {job.customer_name} 
                  </p>
                  <span className='text-orange-500'>
                    {job.customer_hold > 1 ? (<ExclamationTriangleIcon className="h-5 ml-2" />) : ('')}
                  </span>
                </span>
                <p className='text-green-500'>
                  {job.contract_engineer || ''}
                </p>
              </td>

              {/* Job Description Cell */}
              <td className="hidden px-3 py-8 text-sm text-gray-500 sm:table-cell relative">
                {job.job_description}

                {job.eng_status && (
                  <span className={`absolute top-2 right-10 inline-flex items-center rounded-full 
                                px-1 py-0.5 text-xs font-medium text-gray-500 ring-1 ring-gray-300 
                                ${job.eng_status === 'complete' ? 'bg-green-50' : 'bg-yellow-50'}`}>
                    Eng
                  </span>
                )}

                {job.acc_status && (
                  <span className={`absolute top-2 right-2 inline-flex items-center rounded-full 
                                px-1 py-0.5 text-xs font-medium text-gray-500 ring-1 ring-gray-300 
                                ${job.acc_status === 'complete' ? 'bg-green-50' : 'bg-yellow-50'}`}>
                    Ven
                  </span>
                )}
              </td>

              {/* F/P Type Cell */}
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                  job.f_p_type === 'F' ? 'bg-green-50 text-green-700 ring-green-600/20' :
                  'bg-yellow-50 text-yellow-800 ring-yellow-600/20'
                }`}>
                  {job.f_p_type}
                </span>
              </td>

              {/* Price | Tax Cell */}
              <td className={classNames(
                job.prev_wage ? preWageClass : 'hidden px-3 py-4 text-sm text-gray-500 sm:table-cell'
              )}>
                <CurrencyFormat value={job.price}/> 
                <p><Badge feed={job.tax_status} {...taxBadge()} /></p>
              </td>

              {/* Invoiced Rate Cell */}
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {job.invoiced}%
              </td>

              {/* Paid Rate Cell */}
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {job.paid}%
              </td>

              {/* Date Cell */}
              <td className="py-4 pl-1 text-sm text-gray-500">
                <DateFormat date={job.date} />
              </td>

              {/* PM Cell */}
              <td className="py-4 pl-1 text-sm text-gray-500">
                {job.pm}
              </td>

              {/* Notes Cell */}
              <td className="py-4 pl-1 text-sm text-gray-500">
                <button className="text-indigo-600 hover:text-indigo-900" onClick={() => setOpenNotesJobId(job.id)}>
                  <PencilSquareIcon className='h-5 w-5 text-gray-400' aria-hidden="true" />
                </button>
                {openNotesJobId === job.id && (
                  <ModalWindow
                    job={
                      <WipComments
                        onChange={(e) => handleChange(e, job.id)}
                        api={api}
                        inside={'notes'}
                        notes={job.wip_notes}
                        bodyData={bodyDataNotes}
                        val={''}
                        id={job.id}
                        handleNoteChangeParent={handleNoteChangeParent}
                        redirectPath={'/wip_list/aj'}
                        closeNotes={() => setOpenNotesJobId(null)}
                      />
                    }
                    closeNotes={() => setOpenNotesJobId(null)}
                    activeButton={true}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
        )}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Add New Credit Hold</h2>
        <form>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Amount</label>
            <input
              type="number"
              name="amount"
              value={newObjct.amount}
              required={true}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              type="text"
              rows={3}
              name="notes"
              value={newObjct.notes}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-9 float-end">
            <button
              type="button"
              onClick={handleCreditHold}
              className="inline-flex justify-center rounded-md border border-transparent bg-green-600 hover:bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
      <Modal isOpen={isModalFileOpen} onClose={() => setIsModalFileOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Upload Certification File</h2>
        <form onSubmit={handleUploadFile}>
      <div className="form-group">
        <input
          type="file"
          name="file"
          id="file"
          onChange={handleFileChange}
          required
          className="mt-1 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
        />
      </div>
        <div className="mt-9 float-right">
          <button
            type='submit'
            className={`inline-flex justify-center rounded-md border border-transparent
                      py-2 px-4 text-sm font-medium text-white shadow-sm bg-green-600
                      focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
            Upload
          </button>
        </div>
        </form>
      </Modal>
      <br/>
      <br/>
      <br/>
    </>
  )

}
export default WipListPage;