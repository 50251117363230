import React, { useState, useEffect, Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ArrowDownCircleIcon, ArrowUpCircleIcon, DocumentArrowDownIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import FileLocationCp from './FileLocationCp';
import { Modal } from 'react-bootstrap';
import FileUploadModal from './FileUploadModal';
import FileLocation from './FileLocation';
import DateFormat from './DateFormat';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NewsUpdateCp = () => {
  const [newsUpdates, setNewsUpdates] = useState([])
  const [employees, setEmployees] = useState({})
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/widgets_crud.php`;
  const [file, setFile] = useState(null);
  const [access, setAccess] = useState(false);
  const [formData, setFormData] = useState({
      id: '',
      label: '',
      date: '',
      file: '',
      employee_id: ''
  });

  useEffect(() => {
    fetchList(api);
  }, [api])

  const fetchList = async (api) => {
    setLoading(true);
    try {
      const response = await axios.get(api, { params: { getList: 'getList' } });
      if (response.data[0] === 'success') {
        setNewsUpdates(response.data[1]);
        setAccess(response.data[2]);
      } else {
        setNewsUpdates([])
        setAccess(response.data[0]);
      }
    } catch (error) { 
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Trim the comment to remove any leading or trailing spaces
    const trimmedComment = formData.label.trim();

    // Check if the trimmed comment is empty
    if (trimmedComment === '') {
      toast.error('message cannot be blank or just spaces.', { position: 'top-center' });
      return; // Stop form submission
    }
    try {
      const response = await axios.post(`${apiUrl}/widgets_crud.php`, { ...formData, status: 'submitNewsUpdate' }, {
        headers: { 'Content-Type': 'application/json' }
      });
      // Add the new news update to the existing list
      setNewsUpdates((prevNewsUpdates) => [
        {
          id: response.data[0].id, // Assuming backend returns the new ID
          label: formData.label,
          file: '', // Ensure this is set in formData
          date: response.data[0].date || new Date().toISOString().split('T')[0], // Use backend date or current date
          employee_id: response.data[0].employee_id // Set accordingly
        },
        ...prevNewsUpdates, // Add new item at the beginning
      ]);      
      
      if (response.data.success) { closeModal(); }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'label' && value.length > 128) {
      toast.error('Message cannot exceed 128 characters', { position: 'top-center' });
      return;
    }
    setFormData((prevData) => ({
      ...prevData, 
      [name]: value 
    }));
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData({
      id: '',
      label: '',
      date: '',
      file: '',
      employee_id: ''
    });
  }; 

   // Handle file selection for upload
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };
 
  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'submit_file');
    formData.append('id', selectedFileId); // Include the accounting vendor ID
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/news_update_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setNewsUpdates(prev => 
            prev.map(nesUpdate =>
                nesUpdate.id === selectedFileId ? 
                { ...nesUpdate, file: response.data.news_update.file } : nesUpdate
            )
        );

        setFile(null);
        setIsModalFileOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
    }
  };

  const handleDelete = async (newsUpdateId) => {
    try {
      const response = await axios.post(`${apiUrl}/widgets_crud.php`, {
        data: { news_update_id: newsUpdateId },
        status: 'news_update_delete'
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data.success) {
        toast.success("Deleted!", {
          position: "top-center"
        });
        setNewsUpdates(newsUpdates.filter(newsupdate => newsupdate.id !== newsUpdateId));
      } else {
        toast.error(response.data.error || "Failed to delete comment.", {
          position: "top-center"
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! no access to delete.", {
          position: "top-center"
        });
      }
    }
  };


  return (
    <>
      <ul role="list" className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
       {/* <div className='font-semibold bg-gray-700 p-2 rounded-md text-white'>News Update</div> */}
      <div className="flex rounded-md">
        <div className={`${access ? ('w-4/5') :('w-full')} px-1 py-2 sm:px-2 bg-gray-700`}>
          <p className="text-center text-white">News Feed | Docs</p>
        </div>
        {access && (
        <div className="w-1/5 px-3 py-2 sm:px-4 bg-green-600 hover:cursor-pointer hover:bg-green-700" onClick={openModal}>
          <p className="text-center text-white"><PlusCircleIcon className='h-6'/></p>
        </div>
        )}
      </div>
      
      {newsUpdates && (
        <>
        {newsUpdates.map((news) => (
          <li key={news.id} className="flex items-center justify-between gap-x-6 py-2 px-2">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold text-gray-900">{news.label}</p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs text-gray-500">
                <p className="whitespace-nowrap">
                  Posted on <span><DateFormat date={news.date}/></span>
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
              </div>
                <p className="truncate mt-1 flex items-center gap-x-2 text-xs text-gray-500">
                  By {news.employee || 'Admin'}
                </p>
            </div>
            <div className="flex flex-none items-center gap-x-4">

                {access ? (
                  <>
                  <FileLocationCp FileName={news.file}
                                  where={'news_update_file'}
                                  notFoundButton={<ArrowUpCircleIcon className={`h-5 text-green-600 hover:cursor-pointer hover:text-blue-600`}
                                  onClick={() => { setIsModalFileOpen(true); 
                                                  setSelectedFileId(news.id) }} /> }/>
                  </>

                ) : (
                  <>
                  <FileLocation expFile={news.file} where={'news_update_file'} />
                  </>
                )}
              <span className="sr-only">, {news.label}</span>
              {access && (
              <div as="div" className="relative flex-none">              
                <span className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <TrashIcon aria-hidden="true" className="h-5 w-5 text-red-600 hover:cursor-pointer" onClick={() => handleDelete(news.id)} />
                </span>
              </div>
              )}
            </div>
          </li>
        ))}       
        </>
      )}
      </ul>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    New Update
                  </Dialog.Title>
                  <form onSubmit={handleSubmit} className="mt-4">
                    <div className="space-y-4">
                      <div>
                        <label htmlFor="label" className="block text-sm font-medium text-gray-700">
                          Label
                        </label>
                        <input
                          type="text"
                          name="label"
                          id="label"
                          value={formData.label}
                          onChange={handleChange}
                            maxLength="128"  // Limit to 128 characters
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>

                    <div className="mt-6 flex justify-end space-x-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* File Upload Modal */}
      <FileUploadModal
        isOpen={isModalFileOpen}
        onClose={() => setIsModalFileOpen(false)}
        onSubmit={handleUploadFile}
        onFileChange={handleFileChange}
        accept="application/pdf, image/png, image/jpeg" // Accept multiple file types
        title="Upload Reimbursement File"
        label="Select a file (PDF, PNG, JPEG) (Max size 2MB)"/>
    </>
  )}


export default NewsUpdateCp;